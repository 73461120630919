import ConfigService from './config.service'
import CategoryService from './category.service'
import MenuService from './menu.service'
import OrderService from './order.service'
import CMSService from './cms.service'
import LocationService from './location.service';
import AuthService from './auth.service';
import ConceptService from './concept.service';
import GeideaService from './geidea.service';

let Config = new ConfigService()
let Category = new CategoryService()
let Menu = new MenuService()
let Order = new OrderService()
let CMS = new CMSService()
const Location = new LocationService();
const Auth = new AuthService();
const Concept = new ConceptService();
let Geidea = new GeideaService();

export {
  Config,
  Category,
  Menu,
  Order,
  CMS,
  Location,
  Auth,
  Concept,
  Geidea,
}
