import BaseService from '../base.service'

export default class CMSService extends BaseService {

    constructor() {
        super();
        this.baseURL = this.baseURL + '/cms';
    }

    sliders() {
        const url = `${this.baseURL}/sliders?include=slides`;
        return super.get(url);
    }
}
