import state from './config.state';
import getters from './config.getters';
import mutations from './config.mutations';
import actions from './config.actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
