<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
.container{
    width: 100% !important;
}
.v-carousel__controls {
  bottom: unset !important;
  top: 0 !important;
  .v-btn--icon.v-size--small .v-icon, .v-btn--fab.v-size--small .v-icon {
    height: 56px;
    font-size: 56px !important;
    width: 56px;
  }
}
.v-slide-group__content {
  margin-left: -16px !important;
}
</style>
