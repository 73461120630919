import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'home',
    component: () => import('@/layouts/BlankLayout.vue' ),
    meta: { requiresConfig: true },
    children:[
      {
        path:'/home',
        name:'home',
        component: () => import('@/views/home' ),
      },
      {
        path:'/lang',
        name:'lang.index',
        component: () => import('@/views/lang' ),
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue' ),
    meta: { requiresConfig: true },
    children:[
      {
        path:'/menu',
        name:'menu.index',
        component: () => import('@/views/menu' ),
        children:[
          {
            path:'/menu/:catId/customize-item/:itemId',
            name:'menu.customize',
            component: ()=> import('@/views/menu/customize-item')
          },
          {
            path:'/menu/:slug/add-item',
            name:'menu.add',
            component: ()=> import('@/views/menu/add-item')
          },
          {
            path:'/menu/cart',
            name:'menu.cart',
            component: ()=> import('@/views/menu/cart')
          },
          {
            path:'/menu/upsell',
            name:'menu.upsell',
            component: ()=> import('@/views/menu/upsell')
          },
          {
            path:'/menu/payment',
            name:'menu.payment',
            component: () => import('@/views/menu/payment')
          }
        ]
      }
    ]
  },
  {
    path: '/config',
    name: 'config',
    redirect: 'config.login',
    component: () => import('@/views/config' ),
    children:[
      {
        path:'/config/login',
        name:'config.login',
        component: () => import('@/views/config/login')
      },
      {
        path:'/config/form',
        name:'config.form',
        component: () => import('@/views/config/form')
      },
    ]
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/config/clear'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
