import BaseService from '../base.service'

export default class AuthService extends BaseService {

    constructor() {
        super();
    }

    login(payload = {}) {
        let url = `${this.baseURL}/login`;
        return super.post(url, payload);
    }

    logout() {
        let url = `${this.baseURL}/logout`;

        return super.post(url, {});
    }
}
