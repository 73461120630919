export default {
    getConfiguration(state) {
        return {
            concept: state.selectedConcept,
            branch: state.branch,
            terminal: state.terminal,
            terminalId: state.terminalId,
            ipAddress: state.ipAddress,
        };
    },
    getIsConfigured: (state) => state.isConfigured,
    getIsLoggedIn: (state) => state.isLoggedIn,
    getUser: (state) => state.user,
    getUserConcepts: (state) => state.user && state.user.attributes && state.user.attributes.concepts,
    getSelectedConcept: (state) => state.selectedConcept,
}
