export default {
    key: null,
    branch: null,
    terminal: null,
    terminalId: '9999',
    ipAddress: '192.168.1.1',
    isConfigured: false,
    isLoggedIn: false,
    user: null,
    concept: {},
    selectedConcept: {},
}
