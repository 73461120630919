import { SET_SLIDERS } from './types/mutation';

export default {
    setLocale(state, data) {
        state.locale = data
    },
    setOrderType(state, data) {
        state.orderType = data
    },
    [SET_SLIDERS]: (state, sliders) => {
        state.sliders = sliders;
    },
}
