export default {
    setConfiguration(state, {
        concept,
        branch,
        terminal,
        terminalId,
        ipAddress
    }) {
        state.selectedConcept = concept;
        state.branch = branch;
        state.terminal = terminal;
        state.terminalId = terminalId;
        state.ipAddress = ipAddress;
    },
    setIsConfigured(state, data) {
        state.isConfigured = data;
    },
    setIsLoggedIn(state, data) {
        state.isLoggedIn = data;
    },
    clearConfiguration(state, type = '') {
        state.isConfigured = false;
        if (type === 'hard') {
            state.selectedConcept = null;
            state.branch = null;
            state.terminal = null;
            state.terminalId = '9999';
            state.ipAddress = '192.168.1.1';
            state.isLoggedIn = false;
            state.user = null;
        }
    },
    setUser(state, data) {
        state.user = data;
    },
    setSelectedConcept(state, data) {
        state.selectedConcept =  data;
    },
}
