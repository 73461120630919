import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import {Config, Category, Concept} from './services/SOLO'
import { mapMutations, mapGetters } from 'vuex'
import IdleVue from 'idle-vue'

const eventsHub = new Vue()

Vue.config.productionTip = false
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 30000,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresConfig)) {
    if (!store.getters['config/getIsLoggedIn'] && !store.getters['config/getIsConfigured']) {
      next({ name: 'config.login' });
    } else if (store.getters['config/getIsLoggedIn'] && !store.getters['config/getIsConfigured']) {
      next({ name: 'config.form' });
    } else {
      next();
    }
  } else {
    if (!store.getters['config/getIsLoggedIn'] && !store.getters['config/getIsConfigured']) {
      if (to.name === 'config.login') {
        next()
      } else {
        next({ name: 'config.login' });
      }
    } else if (store.getters['config/getIsLoggedIn'] && !store.getters['config/getIsConfigured']) {
      if (to.name === 'config.form') {
        next()
      } else {
        next({ name: 'config.form' });
      }
    } else if (
      store.getters['config/getIsLoggedIn'] &&
      store.getters['config/getIsConfigured'] &&
      to.name === 'config.form'
    ) {
      if (to.name === 'home') {
        next()
      } else {
        next({ name: 'home' });
      }
    } else {
      next();
    }
  }
})

new Vue({
  store,
  router,
  vuetify,
  beforeCreate() {
    this.$nextTick(() => {
      // this.app();
      this.setTheme();
    })
  },
  computed: {
    ...mapGetters({
      getApplications: 'concept/getApplications',
      getConcept: 'concept/getConcept',
      getConfiguration: 'config/getConfiguration',
    })
  },
  methods: {
    ...mapMutations({
      setApplications: 'concept/setApplications',
      setConcept: 'concept/setConcept',
      setMenu: 'cart/setMenu'
    }),
    app() {
      console.log('getConfiguration: ', this.getConfiguration);
      Concept.getApplication().then(response => {
        console.log('Config.getApplication - response: ', response);
        // const apps = response.data.data
        // const concept = response.data.included[0]
        // this.setApplications({...apps.attributes, ...{id: apps.id}})
        // this.setConcept({...concept.attributes,...{id: concept.i}})
        // this.getAppMenu()
        // this.setTheme()
      })
    },
    setTheme() {
      if (this.getApplications?.theme) {
        this.$vuetify.theme.themes.light.primary = this.getApplications.theme.primary_color
        this.$vuetify.theme.themes.light.secondary = this.getApplications.theme.secondary_color
        this.$vuetify.theme.themes.light.background = this.getApplications.theme.background_color || this.getApplications.theme.primary_color
      }
    },
    getAppMenu() {
      Category.cdn(this.getApplications['menu-ref']).then(response => {
        this.setMenu(response.data)
      })
    }
  },
  render: h => h(App)
}).$mount('#app')
