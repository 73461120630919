export default {
    getApplications(state) {
        return state.applications
    },
    getConcept(state) {
        return state.concept
    },
    currency(state) {
        const currencyCode = state
            && state.concept
            && state.concept.attributes
            && state.concept.attributes['currency-code'] || 'SAR';
        return {
            key: currencyCode === 'SAR' ? 'sr' : currencyCode.toLowerCase(),
            value: currencyCode,
        };
    }
}
