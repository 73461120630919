import BaseService from '../base.service';
import store from '@/store';

export default class ConceptService extends BaseService {

    constructor() {
        super();
    }

    get(id) {
        delete this.headers['Solo-App'];
        this.headers['Solo-Concept'] = id;
        this.headers['Authorization'] = 'Bearer ' + store?.getters['config/getUser']?.attributes?.token;
        this.url = `${this.baseURL}/concepts/${id}`;
        return super.get(this.url);
    }
    getApplication(id) {
        this.headers['Authorization'] = 'Bearer ' + store?.getters['config/getUser']?.attributes?.token;
        this.headers['Solo-Concept'] = id;
        this.url = `${this.baseURL}/applications?include=concept`;
        return super.get(this.url);
    }
}
