import { CMS } from '../../services/SOLO';
import { FETCH_CMS_SLIDERS } from './types/action';
import { SET_SLIDERS } from './types/mutation';

export default {
    [FETCH_CMS_SLIDERS]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            CMS.sliders()
                .then((response) => {
                    console.log('CMS.sliders: ', response);
                    commit(SET_SLIDERS, response?.data)
                    resolve(response?.data);
                })
                .catch(reject);
        });
    },
}
