import BaseService from '../base.service';

export default class LocationService extends BaseService {

  constructor() {
    super();
  }

  fetch(conceptId) {
    this.headers['Solo-Concept'] = conceptId;
    const url = `${this.baseURL}/locations`;
    return super.get(url);
  }

  getLocation(id) {
    const url = `${this.baseURL}/locations/${id}`;
    return super.get(url);
  }
}

