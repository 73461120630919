export default class GeideaService {
    baseRoute = process.env.VUE_APP_PAYMENT_ROOT;
    geideaURL = process.env.VUE_APP_GEIDEA_URL;
    geideaPort = process.env.VUE_APP_GEIDEA_PORT;
    socket = null;
    response = null;
    amount = 0;

    constructor() {
    }

    initWebSocket() {
        const self = this;
        this.socket = new WebSocket(this.baseRoute);

        this.socket.onopen = () => {
            console.log('[open] Successfully connected with web ecr server localhost server');
            this.TCPConnect();
            // this.runTransaction(amount);
        };

        this.socket.onclose = (event) => {
            if (event.wasClean) {
                console.log(
                    `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
                );
            } else {
                // e.g. server process killed or network down
                // event.code is usually 1006 in this case
                console.log('[close] Connection died');
            }
        };

        this.socket.onerror = (error) => {
            self.socket.close();
            console.log(`[error] ${error.message}`);
        };
    }

    TCPConnect() {
        const payload = {
            Event: 'CONNECTION',
            Operation: 'CONNECT',
            ConnectionMode: 'TCP',
            ipAddress: this.geideaURL,
            port: this.geideaPort,
        }
        this.socket.send(JSON.stringify(payload));
    }

    runTransaction() {
        const payload = {
            Event: 'TRANSACTION',
            Operation: 'PURCHASE',
            Amount: this.amount,
            PrintSettings: '1',
            AppId: '11',
            ECRNumber: '1234567890123456',
        }
        this.socket.send(JSON.stringify(payload));
    }

    sendPurchase(amount = 0) {
        this.amount = amount;
        this.initWebSocket();
    }

    closeAndSendError(error, reject) {
        this.socket.close();
        reject(error);
    }

    getResponse() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.socket.onmessage = (event) => {
                const data = JSON.parse(event?.data);

                console.log('self.socket.onmessage - event: ', event);

                if (!data) {
                    self.socket.close();
                    return;
                }
                if (
                    data.IsConnected === 'True' &&
                    data.ConnectionMode === 'TCP' &&
                    data.Status === 'PORT_OPENED'
                ) {
                    console.log('PORT_OPENED');
                    self.runTransaction();
                } else if (
                    data.IsConnected === 'False' &&
                    data.ConnectionMode === 'TCP' &&
                    data.Status === 'PORT_CLOSED'
                ) {
                    console.log('PORT_CLOSED');
                    self.socket.close();
                } else if (data.EventName === 'TERMINAL_RESPONSE') {

                    const moreData = JSON.parse(data.JsonResult);

                    console.log('TERMINAL_RESPONSE - JsonResult: ', moreData);

                    if (data.TerminalAction === 'USER_CANCELLED_AND_TIMEOUT') {
                        self.closeAndSendError({
                            reason: 'User cancelled payment on the terminal.',
                            transactionResponse: moreData.TransactionResponse
                        }, reject);
                    }

                    // Logic for assuming terminal is mada (need confirmation)
                    // ref: https://docs.google.com/spreadsheets/d/1mYTWbWzxbNAmsHUWJ4RsPpnhDmUq2NrL/edit#gid=1344384763
                    const tr = parseInt(moreData.TransactionResponse);
                    if (isNaN(tr)) {
                        self.closeAndSendError({
                            reason: `Terminal Response: ${moreData.TransactionResponse} is not a number!`,
                            transactionResponse: moreData.TransactionResponse
                        }, reject);
                        return;
                    }

                    if (
                        // 0 - 100 indicates approval
                        (tr >= 0 && tr <= 100) ||
                        tr === 300 ||
                        tr === 400 ||
                        tr === 500 ||
                        tr === 800
                    ) {
                        resolve(data);
                    } else {
                        self.closeAndSendError({
                            reason: 'Not TransactionResponse 000 (or any other Response that should warrant approval)',
                            transactionResponse: moreData.TransactionResponse
                        }, reject);
                    }
                } else if (data.EventName === 'TERMINAL_ACTION') {
                    const moreData = JSON.parse(data.JsonResult);

                    if (data.TerminalAction === 'USER_CANCELLED_AND_TIMEOUT') {
                        self.closeAndSendError({
                            reason: 'User cancelled payment on the terminal.',
                            transactionResponse: moreData.TransactionResponse
                        }, reject);
                    } else if (data.TerminalAction === 'IDLE_SCREEN') {
                        self.closeAndSendError({
                            reason: 'Terminal display is on IDLE.',
                            transactionResponse: moreData.TransactionResponse,
                        }, reject);
                    }
                }
            }
        })
    }
}
