import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';

import app from './app'
import concept from './concept'
import cart from './cart'
import config from './config'

Vue.use(Vuex);
const vuexPersist = new VuexPersist({
  key: 'white-label-kiosk',
  storage: localStorage
});
export default new Vuex.Store({
  modules: {
    app,
    concept,
    cart,
    config,
  },
  plugins: [vuexPersist.plugin]
});
